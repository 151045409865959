import React, { useState, useEffect, useRef } from 'react'
import { Box, Flex, Button, Text, Icon, Slide } from '@chakra-ui/react'
import { FiChevronUp } from '@react-icons/all-files/fi/FiChevronUp'
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown'
import { EditionStyle, PostStyle, ScreenBreakpoints } from '../../utilities/styles'
import ChapterListView from '../ChapterList'

function ChapterList({ posts, edition }) {
  const [openChapter, setOpenChapter] = useState(false)
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);

  const { innerWidth: width } = window;
  
  const sliderStyle = {
    inset: width > ScreenBreakpoints.tablet ? '80px 0px 0px 80px' : '0px 0px 80px 0px',
    width: 'unset'
  }

  const handleButtonClick = () => {
    openChapter ? setOpenChapter(false) : setOpenChapter(true)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current && 
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current && 
        !buttonRef.current.contains(event.target))
      {
        setOpenChapter(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <Flex alignItems="center" mr="4" display={['flex']}>
      <Button 
        ref={buttonRef}
        onClick={() => handleButtonClick()} 
        bg={PostStyle.ReadingBarButton()}>
        <Text>View Stories</Text>
        <Icon as={openChapter ? FiChevronUp: FiChevronDown} ml="2" />
      </Button>
      { openChapter && (
        <Box 
          position="fixed"
          top={[0, '81px']}
          bottom={['81px', 0]}
          left="0"
          right="0"
          backgroundColor="rgba(0, 0, 0, 0.8)"
        />
      )}
      <Slide 
        direction="right" 
        in={openChapter} 
        style={sliderStyle}>
        <Box position="relative" h="100%">
          <Box 
            ref={wrapperRef}
            position="absolute"
            bottom="0"
            top="0"
            left={['0', '50%']}
            right="0"
            p="4"
            bgColor={EditionStyle.ChapterListBg()}>
              <ChapterListView edition={edition} posts={posts} />
          </Box>
        </Box>
      </Slide>
    </Flex>
  )
}

export default ChapterList
