import React, { useContext, useEffect, useState } from 'react'
import { EditionStyle, PostStyle } from '../../utilities/styles'
import { Box, Flex, Text } from '@chakra-ui/react'
import { CarouselContext } from 'pure-react-carousel';
import { scrollTop, onKeydown } from '../../utilities/event'
import ChapterList from './ChapterList'

function PostHeader({ edition, posts }) {
  const carouselContext = useContext(CarouselContext);
  const [post, setPost] = useState({});
  const [currentSlide, setCurrentSlide] = useState(carouselContext?.state?.currentSlide);
  
  const arrowStyle = {
    color: EditionStyle.ArrowIcon()
  }

  const containerStyle = {
    right: 0,
    zIndex: 1,
    position: 'fixed',
  }

  const checkPostExist = (post, type) => {
    if (type === 'url')
      return post ? post.slug : 'cover'
      
    return post ? post : {}
  }

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  useEffect(() => {
    let slideIndex = currentSlide - 2
    setPost(checkPostExist(posts[slideIndex]))
    
    if (window) {
      window.history.pushState(
        null, 
        null, 
        `/${edition.slug}/${checkPostExist(posts[slideIndex], 'url')}`
      )
    }

    scrollTop()
  }, [currentSlide]);

  useEffect(() => {
    document.onkeydown = onKeydown
    return () => {
      document.onkeydown = null
    }
  }, [])

  return post && Object.keys(post).length ? (
    <Box
      position="relative"
      left={[0, '80px']}
      top={['unset', 0]}
      bottom={[0, 'unset']}
      style={containerStyle}>
      <Flex 
        py="2" 
        height='81px'
        bgColor={EditionStyle.HeaderBg()} 
        boxShadow={['0 -1px 2px 2px rgba(0, 0, 0, 0.05)', '0 1px 2px 0 rgba(0, 0, 0, 0.05)']}
        justifyContent="space-between">
        <Flex>
          
          <Flex 
            ml="4"
            flexDirection="column" 
            justifyContent="center">
            <Text 
              color={PostStyle.ReadingBarText()} 
              fontSize="xs">
              NOW READING · {edition.title}
            </Text>
            <Text fontWeight="bold" color={EditionStyle.ChapterText()}>{post.title}</Text>
          </Flex>
        </Flex>
        <Flex 
          ml="4"
          flexDirection="column" 
          justifyContent="center"
          whiteSpace="nowrap">
          <Box 
            p={[3, 5]} 
            borderLeft={[0, '1px']} 
            borderColor={PostStyle.ReadingBarButtonBorder()}>
            <ChapterList posts={posts} edition={edition}/>
          </Box>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Box></Box>
  )
}

export default PostHeader

