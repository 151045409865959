import React, { useState, useContext, useEffect } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { FiArrowRight } from '@react-icons/all-files/fi/FiArrowRight'
import { CarouselContext } from 'pure-react-carousel';
import { ButtonNext } from 'pure-react-carousel'
import { CommonStyle } from '../../utilities/styles'

function EditionCoverButton() {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext?.state?.currentSlide);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext])

  return (
    <Box 
      display={['block', 'none']}
      position="fixed"
      height="80px"
      bottom="0"
      left="0"
      width="100vw"
      bg="#f0a966"
      color="gray.900">
        { currentSlide === 0 ? (
          <ButtonNext 
            id="button-next" 
            style={CommonStyle.fullsizeButton}>
            <Text fontWeight="bold" fontSize="xl">View Chapters</Text>
            <FiArrowRight fontSize="30" />
          </ButtonNext>
        ): (
          <ButtonNext style={CommonStyle.fullsizeButton}>
            <Text fontWeight="bold" fontSize="xl">Read the Issue</Text>
            <FiArrowRight fontSize="30" />
          </ButtonNext>
        )}
    </Box>
  )
}

export default EditionCoverButton
