import React, { useRef, useEffect } from 'react'
import { Box, Slide } from '@chakra-ui/react'
import { EditionStyle } from '../utilities/styles'

function Popup({ children, openMenu, setOpenMenu, ...props }) {
  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) 
      setOpenMenu(false)
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <>
      {openMenu && (
        <Box 
          position="fixed"
          display="flex"
          top={[0, '80px']}
          bottom={['80px', 0]}
          left="0"
          right="0"
          backgroundColor="rgba(0, 0, 0, 0.8)"
          {...props}/>
      )}
      <Slide 
        direction="bottom" 
        in={openMenu} 
        style={{ zIndex: 10 }}>
        <Box 
          ref={wrapperRef}
          p="4"
          bgColor={EditionStyle.ChapterListBg()}>
          {children}
        </Box>
      </Slide>
    </>
  )
}

export default Popup
