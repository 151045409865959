import React, { useState } from 'react'
import { Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import { FiShare } from '@react-icons/all-files/fi/FiShare'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import Popup from './Popup'

function ShareButtons({ pageUrl }) {
  const [openMenu, setOpenMenu] = useState(false)
  const socialMedias = [
    {id: 1, label: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`, icon: <FaFacebookF />},
    {id: 2, label: 'Twitter', url: `https://twitter.com/intent/tweet?via=JomWebMy&url=${pageUrl}`, icon: <FaTwitter/>},
  ]

  const handleButtonClick = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <div>
      <Flex>
        <IconButton 
          size="sm"
          variant="ghost"
          display={['inline-flex', 'none']}
          icon={<FiShare fontSize="20" />} 
          onClick={handleButtonClick}
          />
        <Flex display={['none', 'flex']} alignItems="center">
          <Text mr="4">Share this article:</Text>
          {socialMedias.map(social => (
            <a 
              key={social.id}
              href={social.url} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{marginRight: '16px'}}>
                {social.icon}
            </a>
          ))}
        </Flex> 
      </Flex>
      <Popup 
        openMenu={openMenu} 
        setOpenMenu={setOpenMenu}
        bottom="0"
        zIndex="1">
        {socialMedias.map((social, idx) => (
          <a 
            key={social.id}
            href={social.url} 
            target="_blank" 
            rel="noopener noreferrer">
              <Flex 
                alignItems="center" 
                py="2" 
                borderBottom={idx === socialMedias.length-1 ? 'none': '1px solid'} 
                borderColor="gray.500">
                <Icon as={() => social.icon} />
                <Text ml="3">{social.label}</Text>
              </Flex>
          </a>
        ))}
      </Popup>
    </div>
  )
}

export default ShareButtons
