import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Link,
  ListItem,
  UnorderedList
} from "@chakra-ui/react"
import { PostStyle } from '../utilities/styles'

function TableOfContent({ items=[] }) {
  return (
    <Box 
      borderRadius="8px"
      border="1px solid"
      borderColor={PostStyle.TitleBg()}
      backgroundColor={PostStyle.TitleBg()}>
      <Accordion allowToggle>
        <AccordionItem border="0">
          <AccordionButton p={6} border="0">
            <Box flex="1" textAlign="left">
              Table of Content
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={6} pb="6">
            <UnorderedList>
              {items.length && items.map((item, idx) => (
                <ListItem my="2" key={idx}>
                  <Link href={`#${item.headingTag}`}>
                    <Text fontSize="lg">{item.headingTitle}</Text>
                  </Link>
                </ListItem>
              ))}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

export default TableOfContent
