import React from 'react'
import { Box, Flex, Text, Heading, Divider } from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ButtonNext } from 'pure-react-carousel'
import { FiArrowRight } from '@react-icons/all-files/fi/FiArrowRight'
import { HomeStyle, CommonStyle } from '../utilities/styles'

export default function HomeLayout({
  title,
  content,
  image,
}) {
  return (
    <Box>
      <Box 
        pt={[6, 12, 24]} 
        px={[6, 12, 16]}
        overflowY={['auto', 'auto', 'hidden']}>
        <Flex>
          <Box flex="1">
            <Box mb="4" display={['block', 'none']}>
              <GatsbyImage image={getImage(image)} alt="home" />
            </Box>
            <Heading size="2xl"  mb={[8, 12]}>{title}</Heading>
            <Text fontSize="xs" mb="4">COVER STORY</Text>
            <Box 
              w={["100%", "70%"]}
              mb="8" 
              fontSize="md" 
              style={HomeStyle.ContentBox}
              dangerouslySetInnerHTML={{__html: content}} />
          </Box>

            <Box textAlign="center" display={['none', 'block']}>
              <Text whiteSpace="nowrap" mb="4">START HERE</Text>
              <ButtonNext style={CommonStyle.roundedButton}>
                <FiArrowRight fontSize="24" color={HomeStyle.ArrowIcon} />
              </ButtonNext>
              <Text whiteSpace="nowrap" mt="4">NAVIGATE / DRAG</Text>
            </Box>
        </Flex>

        <Divider display={['none', 'none', 'block']} mb="8" colorScheme="whiteAlpha"/>
        <Box display={['none', 'block']}>
          <GatsbyImage image={getImage(image)} alt="home" />
        </Box>
      </Box>
    </Box>
  )
}


