import React from 'react'
import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { getSrc } from 'gatsby-plugin-image'
import ChapterList from '../ChapterList'

function EditionChapter({ edition, posts, site }) {
  return (
    <Grid  minHeight="100vh" templateColumns={['', '', 'repeat(2, 1fr)']}>
      <GridItem>
        <Box 
          p="10"
          h="100%"
          flex="1"
          background={`
            linear-gradient(0deg, rgba(0, 0, 0, 0.6), 
            rgba(0, 0, 0, 0.6)), 
            url(${getSrc(edition.editionAdditionalFields.coverImage?.image?.localFile)}),
            no-repeat,
            center
          `}
          backgroundSize="cover">
          <Text as="h1" fontSize={['3xl', '6xl']} w="60%" color="white">{site?.siteMetadata?.title}</Text>
        </Box>
      </GridItem>
      <GridItem>
        <ChapterList edition={edition} posts={posts} showButton />
      </GridItem>
    </Grid>
  )
}

export default EditionChapter
