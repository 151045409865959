import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import dateFormat from 'dateformat'
import { getSrc } from 'gatsby-plugin-image'
import { 
  Box, 
  Flex, 
  Grid, 
  GridItem, 
  Heading, 
  Text, 
  Image
} from '@chakra-ui/react'
import { PostStyle } from '../utilities/styles'
import { ButtonNext, ButtonBack } from 'pure-react-carousel'
import MoreStories from '../components/MoreStories'
import Progress from '../components/Progress'
import ShareButtons from '../components/ShareButtons'
import TableOfContent from '../components/TableOfContent'

const Post = ({ post, edition }) => {
  const [pageUrl, setPageUrl] = useState('')

  useEffect(() => {
    setPageUrl(window.location.href)
  }, [])
  
  function getReadingTime() {
    const wpm = 475;
    const words = post?.content.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time + ' mins read';
  }

  function formatDate(date) {
    return dateFormat(new Date(date), 'mmmm dd yyyy')
  }

  const headingStyle = {
    background: 'linear-gradient(to right,  #ffb74a 0%, rgba(232, 86, 76, 1))',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  }

  const StyledBox = styled(Box)`
    line-height: 2em;
    
    & > p {
      display: block;
      margin-top: 1em;
      margin-bottom: 1em;
    }
  `;

  return (
    <Box backgroundColor={PostStyle.Bg()} height="100vh" pb="20" overflowY="auto">
      <Box 
        backgroundColor="gray.300"
        backgroundImage={getSrc(post.featuredImage?.node?.localFile)}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        height={['200', '300', '350']}
        position="relative"
      >
        <Grid 
          position="absolute" 
          bottom={["-90%", "-50%"]} 
          width="100%"
          templateColumns="repeat(11, 1fr)">
          <GridItem 
            px="6"
            colStart={[1, 2, 2, 3]} 
            colEnd={[12, 11, 11, 10]} 
            position="relative">
            <Box 
              py={["7", "10" ]}
              px={["4", "10"]}
              borderRadius="lg"
              textAlign="center" 
              boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 4px 6px -1px rgba(0, 0, 0, 0.1)"
              backgroundColor={PostStyle.TitleBg}
            >
              <Heading display={['none', 'block']} as="h1" size="2xl" style={headingStyle}>{post.title}</Heading>
              <Heading display={['block', 'none']} as="h1" size="xl" style={headingStyle}>{post.title}</Heading>
              <Flex py="5" alignItems="center" justifyContent="center">
                <Image 
                  src={post.author.node.avatar.url} 
                  borderRadius="50%"
                  height="10"
                  width="10"
                />
                <Box ml={['3', '5']} textAlign="left">
                  <Text 
                    my="0"
                    textTransform="capitalize" 
                    color={PostStyle.PublisherNameText()} 
                    fontWeight="semibold">
                    By {post.author.node.name}
                  </Text>
                  <Text 
                    my="0"
                    textTransform="uppercase" 
                    color={PostStyle.PublishedTimeText()} 
                    fontSize="xs">
                    Published at {formatDate(post.date)}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </GridItem>
        </Grid>
      </Box>
      <Grid templateColumns="repeat(11, 1fr)">
        <GridItem colStart={[1, 2, 2, 3]} colEnd={[12, 11, 11, 10]} px="6" pt="52">
          <Flex mb="10" alignItems="center" justifyContent="space-between">
            <Flex>
              <Text 
                my="0"
                px="2"
                fontSize="sm"
                color="black" 
                backgroundColor="#f6ad55" 
                borderRadius="20px" 
              >{post.categories.nodes[0].name}</Text>
              <Text ml="3" my="0">{getReadingTime()}</Text>
            </Flex>
            <ShareButtons pageUrl={pageUrl} />
          </Flex>

          {post?.postAdditionalFields?.tableOfContent?.length && (
            <TableOfContent items={post?.postAdditionalFields?.tableOfContent}/>
          )}
          <StyledBox 
            mb="10"
            textAlign="justify" 
            lineHeight="28px" 
            dangerouslySetInnerHTML={{__html: post?.content}}/>
          {/* <MoreStories /> */}
          {/* <Progress location="bottom" /> */}
        </GridItem>
      </Grid>
      <Box opacity="0">
        <ButtonBack id="button-back"/>
        <ButtonNext id="button-next"/>
      </Box>
    </Box>
  )
}

Post.Layout = Layout
export default Post