import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import EditionChapterLayout from '../components/edition/EditionChapter'
import EditionCoverButton from '../components/edition/EditionCoverButton'
import HomeLayout from '../components/Home'
import Post from '../components/Post';
import PostHeader from '../components/postHeader'
import { Box } from '@chakra-ui/react'
import { onKeydown } from '../utilities/event'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css'

function EditionCover({ pageContext }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const { edition, post, posts, site } = pageContext
  
  useEffect(() => {
    document.onkeydown = onKeydown
    return () => {
      document.onkeydown = null
    }
  }, [])

  useEffect(() => {
    if (posts.length && post) {
      const idx = posts.findIndex(e => e.id === post.id)
      idx >= 0 && setCurrentSlide(idx + 2)
    }
  }, [posts])

  return (
    <Box w="100%" h="100%">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={posts.length + 2}
        currentSlide={currentSlide}
        isIntrinsicHeight={true}
        dragStep={1}>
        <PostHeader edition={edition} posts={posts}/>
        <Slider>
          <Slide>
            <HomeLayout 
              title={edition.title}
              slug={edition.slug}
              content={edition.content}
              image={edition.featuredImage?.node?.localFile}/>
          </Slide>
          <Slide>
            <EditionChapterLayout 
              edition={edition} 
              posts={posts}
              site={site}/>
          </Slide>
            {posts.map(post => (
              <Slide key={post.id} style={{height: '100vh'}}>
                <Post 
                  edition={edition}
                  post={post} />
              </Slide>
            ))}
        </Slider>

        {/* 
          put here instead of on each slide because 
          position fixed not working when parent has 
          transform property 
        */}
        <EditionCoverButton 
          edition={pageContext.edition.slug} 
          firstIssue={pageContext.posts[0].slug}/>
      </CarouselProvider>
    </Box>
  )
}

EditionCover.Layout = Layout
export default EditionCover